import * as React from "react";
import { Header, Container, Segment, Icon } from "semantic-ui-react";
import { withLayout } from "../components/Layout";
import PageTitle from "../components/PageTitle";

const AboutPage = () => {
  return (
    <Container>
      <PageTitle
        iconName="info"
        title="About"
        subtitle="A page dedicated to information about me."
      />
      <p>Page from Philipp Thaler</p>
    </Container>
  );
};

export default withLayout(AboutPage);
